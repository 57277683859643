import Head from 'next/head';
import React from 'react';
import Header from '../components/chelsea/Header';
import Footer from '../components/chelsea/Footer';
import FooterMulti from '../components/chelsea/FooterMulti';

import { useAppContext } from '../util/context';

const Post = ({ pageProps = {}, children }) => {
    const context = useAppContext();

    function renderExamplePostContent() {
        const className = `${
            context?.hideNav ? '' : 'pt-[64px] xl:pt-[128px]'
        }   text-white '`;

        return (
            <div className={' w-full '}>
                <Header {...pageProps} />
                <main className={className + ``}>{children}</main>
                {process?.env?.NEXT_PUBLIC_SUBSITE=='fitness' &&
                    <Footer {...pageProps} />
                }
                {process?.env?.NEXT_PUBLIC_SUBSITE!='fitness' &&
                    <FooterMulti {...pageProps} />
                }
            </div>
        );
    }

    return <div>{renderExamplePostContent()}</div>;
};

export default Post;
