import React, { useEffect, useContext } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { renderGutenbergBlocks } from '../util/blocks';
import { flatListToHierarchical } from '../util/wordpress';
import Post from '../layouts/Post';

import { LocationContext } from '../util/LocationProvider';

import * as cms from '../util/api';

// Active Locations
const locations = [
    { slug: 'chelsea',  path: 'new-york/chelsea' },
    { slug: 'stamford', path: 'connecticut/stamford' },
    { slug: 'brooklyn', path: 'new-york/brooklyn' },
];

export default function Page(props) {
    let LocationManager = useContext(LocationContext);
    LocationManager?.setSitePlaceholders(props?.placeholders?.data?.placeholders?.edges);

    const router = useRouter();

    if (props?.is404) { // Redirect 404s to the homepage.
        useEffect(() => {
            router.replace("/");
        });
        return (<></>);
    }


    // Context is finicky passing between _app.js and pages for SSR, run the following checks client side only. We don't need them in SSR anyway.
    // https://github.com/vercel/next.js/issues/4194
    if (process?.env?.NEXT_PUBLIC_USE_LOCATIONS=='1' && typeof LocationManager?.isLocalePath == 'function') { // Client only.
        // Checks location formatting in path. True if it's correctly formatted, returns correctly formatted redirect path if not.
        let localePath = LocationManager?.isLocalePath(router.asPath.split('?')[0]);

        // Get the intended page location out of the path.
        let linkLocale = LocationManager?.getLocaleFromPath((localePath!==true ? localePath : router.asPath.split('?')[0]));

        if (localePath !== true) { // Wasn't a correctly formatted location path, redirect.

            useEffect(() => {
                LocationManager?.setLocation(process?.env?.NEXT_PUBLIC_SUBSITE, linkLocale, false); // Sync stored location before redirect.
                router?.replace(localePath);
            });
            return (<></>);

        } else { // Is a location path.
            // Check if this page supports the intended location?
            if (props?.pageLocation?.includes('all') || !props?.pageLocation?.length || props?.pageLocation?.includes(linkLocale))  {
                // Check that the intended page location in the link matches the currently set location.
                if (linkLocale && (linkLocale != LocationManager?.getLocation())) { // No? Update the Location.
                    LocationManager?.setLocation(process?.env?.NEXT_PUBLIC_SUBSITE, linkLocale, false);
                }

            } else { // This location on this path is not supported. Redirect to homepage. 
                useEffect(() => {
                    router.replace("/");
                });
                return (<></>);
            }
        }
    }


    let urlPath = router.asPath.split('?')[0];
    switch (urlPath) { // Alias /welcome
        case '/new-york/chelsea/welcome':
        case '/new-york/brooklyn/welcome':
        case '/connecticut/stamford/welcome':
            urlPath = '/';
    }
    let canonical = `https://${process?.env?.NEXT_PUBLIC_SUBSITE}.chelseapiers.com${urlPath}`;

    // Process Metatag components for a match.
    const blocks = props?.blocks;
    const metatags = LocationManager?.getMetatags(blocks, props?.locale);

    const title = metatags?.data?.title ? metatags?.data?.title : '';
    const description  = metatags?.data?.description ? metatags?.data?.description : '';

    // Check if metatag image is set, if not use the default.
    let image = '';
    if (metatags?.data?.image) {
        image = metatags?.meta.images?.[metatags.data.image]?.src;
    } else {
        image = 'https://dirk30xtfel0k.cloudfront.net/wp-content/uploads/2023/02/chelsea-piers-logo-1024x512.png';
    }

    // Location-based GTM codes.
    let GTM = '';
    switch(LocationManager.getLocation()) {
        case 'chelsea':
            GTM = process?.env?.NEXT_PUBLIC_GTM_CHELSEA;
            break;
        case 'stamford':
            GTM = process?.env?.NEXT_PUBLIC_GTM_STAMFORD;
            break;
        default:
            GTM = process?.env?.NEXT_PUBLIC_GTM;
            break;
    }

    return (<>
        <Post pageProps={props}>
            <Head>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','${GTM}');`
                    }}
                />
                <title>{title}</title>
                {canonical && <link rel="canonical" href={canonical} />}
                {description && <meta name="description" content={description} />}
                {image && <meta name="og:image" content={image} />}
                <meta name="google-site-verification" content="PEgaeIjhkjlze6OBfDB9D00xdNiz-GmC7juTvciIiL8" />
                <link rel="shortcut icon" href="/favicon.svg" />
            </Head>
            <noscript
                dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
                }}
            />
            {renderGutenbergBlocks(blocks, props)}
        </Post>
    </>);
}

export async function getStaticPaths(context) {
    /**
     * Grab pages from the CMS, (also unpublished pages for stage only).
     */
    const results = await cms.getPages();

    let paths = ["/"];

    if (process?.env?.NEXT_PUBLIC_USE_LOCATIONS == 1) {
        for (var i=0; i<locations.length; i++) {
            for (var j=0; j<results?.data.contentNodes.nodes?.length; j++) {
                let page = results?.data.contentNodes.nodes[j];
                let locale = page?.subsite?.locale;

                if (page?.subsite?.subsite != process?.env?.NEXT_PUBLIC_SUBSITE) {
                    continue;
                }

                if (page?.subsitePath?.path == '/') {
                    continue;
                }

                if (!locale.length || locale.includes('all') || locale.includes(locations[i]?.slug)) {
                    let path = '/' + 
                        locations[i]?.path + 
                        (page?.subsitePath?.path?.startsWith('/') ? page.subsitePath.path : '/' + page.subsitePath.path);
                    if (!paths.includes(path)) {
                        paths.push(path);
                    }
                }
            }
        }

    } else {
        paths = results.data.contentNodes.nodes
            .filter((page) => { // filter pages that don't have the Subsite Path field.
                if (page?.subsite?.subsite != process?.env?.NEXT_PUBLIC_SUBSITE) return false;
                if (page?.subsitePath?.path) return true;
                return false;
            })
            .map((page) => (page?.subsitePath?.path.startsWith('/') ? page.subsitePath.path : '/' + page.subsitePath.path));
    }

    return { paths, fallback: 'blocking' };
}

export async function getStaticProps(context) {
    let { page = '/' } = context.params;
    let vars = {}; 

    let locale = '';

    if (Array.isArray(context?.params?.page)) {
        page = '/' + context?.params?.page?.join('/');
    }

    // token will be set for stage/preview, otherwise false.
    const token = await cms.getBearerToken(page);
    const preview = cms.isPreviewPath(page); // Is this a preview path?
    const stage = (process?.env?.NEXT_PUBLIC_STAGE=='1'); // Is this stage?
    page = page.replace('___CMS_PREVIEW', ''); // Remove Preview flag.


    /**
     * Location path is added on the front. Strip out the location
     * path to query the ID from the CMS by path.
     */
    for (var i=0; i<locations.length; i++) {
        if (page.includes(locations[i].path)) {
            page = page.replace('/'+locations[i].path, '');
            locale = locations[i]?.slug;
        }
    }

    // GraphQL: GET_PAGE_ID
    vars = {
        subsite: process?.env?.NEXT_PUBLIC_SUBSITE,
        path: page,
    };
    const pageId = await cms.getPageId(vars, token); 
    if (!pageId) { // If no page ID, redirect to 404.
        return { props: { is404: true } };
    }

    // GraphQL: GET_PAGE
    vars = {
        id: pageId,
        idType: 'ID',
    };
    const pageQuery = await cms.getPage(vars, token, preview);
    const pageData = pageQuery?.contentNode;
    const menuData = pageQuery?.menus?.nodes;

    if (
        pageData==undefined || // No data -or-
        (stage==false && pageData?.status!='publish') // ... not stage and not published.
    ) {
        return { props: { is404: true } };
    }

    // Global stuff: Pull options, defaults, and placeholders.
    const options  = await cms.getOptions(); // GraphQL: GET_OPTIONS
    const defaults = await cms.getDefaults(); // GraphQL: GET_DEFAULTS
    const placeholders = await cms.getPlaceholders(); // GraphQL: GET_PLACEHOLDERS

    let {
        title,
        blocks,
        contentType: {
            node: { name: contentType }
        },
        seo
    } = pageData;
    let pageLocation = pageData?.subsite?.locale;
    // Menus

    // Some components need additional information from Wordpress, only query if it's needed.
    let componentQueries = {};
    let restrictedCoreLocation = false; // Location limiting field array when location restriction is active.
    let filteredBlocks = [];
    for (let i=0; i<blocks?.length; i++) { 
        let _block = blocks[i]; 
        let block= {};
        switch (blocks[i]?.name) {             
            case 'acf/offer-banner':
                block = JSON.parse(blocks[i]?.attributesJSON);
                // GraphQL: GET_OFFER
                vars = {
                    id: block?.data?.offer,
                };
                let banner = await cms.getOffer(vars, token);
                if (banner) {
                    componentQueries.banners = componentQueries?.banners || [];
                    componentQueries.banners.push(banner);
                }
                break;
            
            case 'acf/card-slider':
                block = JSON.parse(blocks[i]?.attributesJSON);
                componentQueries.cardSlider = componentQueries.cardSlider || {};
                if (block?.data?.slides_0_icon) {
                    for (let i=0; i<block?.data?.slides; i++) {
                        let img_id = block?.data?.[`slides_${i}_icon`];
                        if (!img_id) continue;
                        vars = {
                            id: img_id, 
                        };
                        var img = await cms.getImageById(vars, token);
                        componentQueries.cardSlider[img_id] = img;
                    };
                }

                break;

            case 'acf/three-col-grid':
                block = JSON.parse(blocks[i]?.attributesJSON);
                componentQueries.videos = componentQueries.videos || {};
                let cardCount = block?.data?.cards;
                for (let i=0; i<cardCount; i++) {
                    let vid_id = block?.data?.[`cards_${i}_video`];
                    if (!vid_id) continue;
                    vars = {
                        id: vid_id,
                    };
                    var vid = await cms.getVideoById(vars, token);
                    componentQueries.videos[vid_id] = vid;
                }
                break;
    
            case 'acf/summer-camps': // Pull Camps to build the search component.
                if (!componentQueries?.camps) {
                    // GraphQL: GET_SUMMER_CAMPS
                    componentQueries.camps = await cms.getSummerCamps();
                }
                break;

            case 'acf/birthday-parties': // Pull parties to build the search component.
                if (!componentQueries?.parties) {
                    // GraphQL: GET_BIRTHDAY_PARTIES
                    componentQueries.parties = await cms.getBirthdayParties();
                }
                break;

            case 'acf/schedule-classes-youth': // Pull Schedule filters.
                if (!componentQueries?.filters) {
                    // GraphQL: GET_SCHEDULE_FILTERS
                    componentQueries.filters = await cms.getScheduleFilters();
                }
                break;

            case 'acf/offer-notice-bar': // Pull notices for this page.
                try {
                    block = JSON.parse(blocks[i]?.attributesJSON);
                    // GraphQL: GET_OFFER_NOTICES
                    vars = {
                        id: block?.data?.offer,
                    };
                    const offerData = await cms.getOfferNotices(vars, token);
                    componentQueries.offers = componentQueries?.offers || [];
                    componentQueries.offers.push(offerData);

                } catch (e) { console.error(e); }
                break;

            case 'acf/coaches-sports-listing': // Pull Sports Coaches for listing.
                // GraphQL: GET_COACHES_SPORTS
                componentQueries.coaches = await cms.getCoachesSports();
                break;

            case 'acf/location-content': // Start location restricted core block section.
                // When the location restriction starts save the location filter to be added to core blocks.
                try {
                    block = JSON.parse(blocks[i]?.attributesJSON);
                    restrictedCoreLocation = {
                        data: {
                          limitDisplay: block?.data?.limitDisplay,
                          _limitDisplay: block?.data?._limitDisplay,
                          displayLocations: block?.data?.displayLocations,
                          _displayLocations: block?.data?._displayLocations
                        }
                    };
                } catch (e) { console.error(e); }
                break;

            case 'acf/location-content-end': // End location restricted core block section.
                // When the location restriction ends clear the filter variable.
                restrictedCoreLocation = false;
                break;

            // Core Blocks. Check if location restriction is active, apply that filter to blocks.
            case 'core/column':
            case 'core/columns':
            case 'core/group':
            case 'core/image':
            case 'core/paragraph':
            case 'core/embed':
            case 'core/spacer':
            case 'core/list':
            case 'core/button':
            case 'core/buttons':
            case 'core/heading':
            case 'core/table':
            case 'core/html':
                // If restrictedCoreLocation is an object with field merge them into the core block.
                if (typeof restrictedCoreLocation == 'object') {
                    let coreBlock = {};
                    let blockJson = JSON.parse(blocks[i]?.attributesJSON);
                    coreBlock.attributesJSON = JSON.stringify(Object.assign({}, blockJson, restrictedCoreLocation));
                    _block = {
                        ...blocks[i],
                        ...coreBlock,
                    };
                }
                break;

            default: break;
        }
        filteredBlocks.push(_block);
    }

    let social = await cms.getSocialLinks();
    social = social ? social : {};

    const menusHierarchy = [];
    for (const { key, name, slug, locations, menuItems } of menuData) {
        menusHierarchy.push({
            key,
            name,
            slug,
            locations,
            menuItems: flatListToHierarchical(menuItems.nodes)
        });
    }
    const menus = {};
    menusHierarchy.map((menu) => (menus[menu.slug] = menu));
    //\ Menus

    let mega = false;
    let megaMenu = `${process.env.NEXT_PUBLIC_SUBSITE}-menu`;
    if (menus[megaMenu]) {
        // GraphQL: GET_MEGA_MENU
        vars = {
            id: menus[megaMenu]?.name,
            idType: 'NAME',
        };
        mega = await cms.getMegaMenu(vars, token);
    }


    let returnObject = {
        props: {
            title,
            blocks: filteredBlocks,
            options: options?.data?.options?.acf_site_options,
            defaults: defaults?.data,
            menus: menus,
            contentType,
            seo,
            mega,
            placeholders,
            pageLocation,
            componentQueries,
            locale,
            social
        }
    }

    // We only want to revalidate the classes schedule page
    if (page === 'class-schedule') { 
        returnObject['revalidate'] = 10;
    }

    if (preview || stage) {
        returnObject['revalidate'] = 1;
    }
    
    return returnObject;
}

